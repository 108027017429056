import { Suspense, useEffect, lazy } from "react";
import { ToastContainer, toast } from "react-toastify";
import LoadingCard from "./components/card/LoadingCard";
import Navbar from "./components/nav/Navbar";
import AuthToken from "./auth/authToken";
import Aos from "aos";
import axios from "axios";
import { Switch, Route, Redirect } from "react-router-dom";
import Contact from "./pages/Contact";
import CopyTrading from "./pages/subPages/CopyTrading";
import Forex from "./pages/subPages/Forex";
import Cryptos from "./pages/subPages/Cryptos";
import Options from "./pages/subPages/Options";
import Stocks from "./pages/subPages/Stocks";
import AboutMining from "./pages/subPages/mining/About";
import BitcoinMining from "./pages/subPages/mining/Bitcoin";
import DogecoinMining from "./pages/subPages/mining/Dogecoin";
import About from "./pages/AboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import NotFound from "./components/page/NotFound";
import InvestmentPrograms from "./pages/InvestmentPrograms";
import WhyUs from "./pages/WhyUs";

const LandingPage = lazy(() => import("./pages/LandingPage"));
const Auth = lazy(() => import("./auth/Auth"));
const Index = lazy(() => import("./app/Index"));
const Admin = lazy(() => import("./app/Admin"));

// const URL = "http://localhost:5040/api/v1";
const URL = "https://tradeoptimal.accessstocklink.com/api/v1"; //"https://api.capitalgainstradex.net/api/v1";//"https://capitalgainstradex.primefbx.com/api/v1";

const tsStyle = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const App = () => {
  const {
    token,
    otp,
    userID,
    setOTPBoolean,
    removeOTPBoolean,
    user,
    removeToken,
    setToken,
  } = AuthToken();

  const logoutUser = () => {
    removeToken();
  };

  const successToast = (success) => toast.success(`${success}`, tsStyle);
  const errorToast = (error) => toast.error(`${error}`, tsStyle);

  useEffect(() => {
    // window.process = {
    // ...window.process,
    // };

    // console.log("PROCESS: ", window);

    window.scrollTo(0, 0);

    Aos.init({
      duration: "1600",
    });
  });

  const reloadUser = () => {
    axios({
      method: "GET",
      headers: {
        "x-auth-token": token,
      },
      url: `${URL}/users/${user._id}`,
    })
      .then((res) => {
        setToken(token, res.data.data.user);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Suspense fallback={<LoadingCard />}>
      <div id="google_translate_element"></div>
      <ToastContainer />
      <Navbar
        token={token}
        removeToken={logoutUser}
        successToast={successToast}
        currentUser={JSON.parse(window.localStorage.getItem("cgbroker_user"))}
      />
      <div className="container-fluid">
        <Switch>
          <Route path="/" exact>
            <LandingPage
              successToast={successToast}
              errorToast={errorToast}
              token={token}
            />
          </Route>
          <Route path="/investment-programs" exact>
            <InvestmentPrograms
              successToast={successToast}
              errorToast={errorToast}
              token={token}
            />
          </Route>
          <Route path="/why-us" exact>
            <WhyUs
              successToast={successToast}
              errorToast={errorToast}
              token={token}
            />
          </Route>
          <Route path="/contact" exact>
            <Contact
              successToast={successToast}
              errorToast={errorToast}
              token={token}
            />
          </Route>
          <Route path="/about" exact>
            <About
              successToast={successToast}
              errorToast={errorToast}
              token={token}
            />
          </Route>
          <Route path="/privacypolicy" exact>
            <PrivacyPolicy
              successToast={successToast}
              errorToast={errorToast}
              token={token}
            />
          </Route>
          <Route path="/terms" exact>
            <Terms
              successToast={successToast}
              errorToast={errorToast}
              token={token}
            />
          </Route>
          <Route path="/copytrading" exact>
            <CopyTrading
              successToast={successToast}
              errorToast={errorToast}
              token={token}
            />
          </Route>
          <Route path="/forex" exact>
            <Forex
              successToast={successToast}
              errorToast={errorToast}
              token={token}
            />
          </Route>
          <Route path="/stocks" exact>
            <Stocks
              successToast={successToast}
              errorToast={errorToast}
              token={token}
            />
          </Route>
          <Route path="/cryptos" exact>
            <Cryptos
              successToast={successToast}
              errorToast={errorToast}
              token={token}
            />
          </Route>
          <Route path="/options" exact>
            <Options
              successToast={successToast}
              errorToast={errorToast}
              token={token}
            />
          </Route>
          <Route path="/mining/about" exact>
            <AboutMining
              successToast={successToast}
              errorToast={errorToast}
              token={token}
            />
          </Route>
          <Route path="/mining/bitcoin" exact>
            <BitcoinMining
              successToast={successToast}
              errorToast={errorToast}
              token={token}
            />
          </Route>
          <Route path="/mining/dogecoin" exact>
            <DogecoinMining
              successToast={successToast}
              errorToast={errorToast}
              token={token}
            />
          </Route>
          <Route path="/app">
            {token ? (
              <Admin
                devApi={`${URL}/`}
                devURL={URL}
                removeToken={removeToken}
                successToast={successToast}
                errorToast={errorToast}
                token={token}
                admin={JSON.parse(window.localStorage.getItem("cgbroker_user"))}
                reloadUser={reloadUser}
              />
            ) : (
              <Auth
                devApi={`${URL}/`}
                setToken={setToken}
                successToast={successToast}
                errorToast={errorToast}
                otp={otp}
                setOTPBoolean={setOTPBoolean}
                removeOTPBoolean={removeOTPBoolean}
                userID={userID}
              />
            )}
          </Route>
          <Route path="/user">
            {token ? (
              <Index
                devApi={`${URL}/`}
                devURL={URL}
                removeToken={logoutUser}
                successToast={successToast}
                errorToast={errorToast}
                token={token}
                currentUser={JSON.parse(
                  window.localStorage.getItem("cgbroker_user")
                )}
                reloadUser={reloadUser}
              />
            ) : (
              <Auth
                devApi={`${URL}/`}
                setToken={setToken}
                successToast={successToast}
                errorToast={errorToast}
                otp={otp}
                setOTPBoolean={setOTPBoolean}
                removeOTPBoolean={removeOTPBoolean}
                userID={userID}
              />
            )}
          </Route>
          <Route path="/auth">
            {token ? (
              <Redirect to="/user" />
            ) : (
              <Auth
                devApi={`${URL}/`}
                setToken={setToken}
                successToast={successToast}
                errorToast={errorToast}
                otp={otp}
                removeOTPBoolean={removeOTPBoolean}
                setOTPBoolean={setOTPBoolean}
                userID={userID}
              />
            )}
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </div>
    </Suspense>
  );
};

export default App;
